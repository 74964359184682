@charset "euc-kr";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
/* @import url(print.css) print;
css ���� �� 
*/

*{ margin:0; border:0; }
body { font-size:14px; color:#4b4948; font-family:'Noto Sans KR',dotum,Tahoma,Verdana,AppleGothic,sans-serif; letter-spacing:-1px; }
div, p, a { line-height:1.6; }

a:link { color:#4b4948; cursor:pointer; text-decoration:none; }
a:visited { color:#4b4948; cursor:pointer; text-decoration:none; }
a:active, a:hover, a:focus { color:#4b4948; cursor: pointer; text-decoration:none; }

input, textarea, select { font-size:1em; color:#4b4948; font-family:'Noto Sans KR',dotum,Tahoma,Verdana,AppleGothic,sans-serif; font-weight:400; }
input.radio, input.check, input.btn { border:0 !important; }
input.radio { margin-bottom:1px; }
label.chk { display:inline-block; margin:2px 15px 0 4px; }
label.chk2 { display:inline-block; margin:2px 5px 0 4px; }

ul,ol, ol li { list-style:none; padding:0; }
form { margin:0; padding:0; }
a img { border:0; }
noscript { position:absolute; z-index:10; background:#efefef; }
noscript p { margin:0; padding:0; }
em { font-style:normal; }
label { cursor:pointer; }
legend { visibility:hidden; height:0; width:0; font-size:0; line-height:0; overflow:hidden; position:absolute; }
caption { visibility:hidden; height:0; width:0; font-size:0; line-height:0; overflow:hidden; }
form fieldset { border:none; padding:0; margin:0; }
img { vertical-align:middle; }
h1,h2,h3,h4,h5,h6 { line-height: 1em; font-weight:normal; }
select { background:none; -webkit-appearance:none; -moz-appearance:none; }
select:before { /* ȭ��ǥ ��ü */ content:""; position:absolute; top:50%; right:15px; width:0; height:0; margin-top:-1px; border-left:5px solid transparent; border-right:5px solid transparent; border-top:5px solid #333; }
img {font-size:0; line-height:0; max-width:100%; }
select::-ms-expand { display:none; }

/* common */
#skip, ul.skiplist li.skip, .skipnavi, caption, legend, span.skip { position: absolute ; font-size: 0px; line-height: 0px; overflow: hidden; visibility: hidden; text-indent: -1000px; }
.hide { display:none; }
.clfix:after { content: ""; display: block; clear: both; }

/* SKIP navigation */
.skipNav { position:absolute; top:0; left:0; list-style:none; margin:0; padding:0; width:20em; z-index:99999; }
.skipNav li { display:inline; margin:0; padding:0; }
.skipNav li a { position:absolute; display:block; top:-200px; left:0; padding:5px 10px; background-color:#fafafa; color:#333; }
.skipNav li a:focus { top:0; left:0; }

/* layout */
.inner { max-width:1000px; margin:0 auto; }
.content { width:auto; margin:0 5%; }

/**/
header { position:relative; height:55px; margin-bottom:40px; text-align:center; vertical-align:middle;
	background-image:url('../images/header_bg_left.png'), url('../images/header_bg_right.png'); background-color:#ec2329; 
	background-size:contain; background-position:left center, right center; background-repeat:no-repeat; }
header .back { position:absolute; top:50%; left:5%; display:block; width:22px; height:20px; transform:translate(0,-50%);
	background:url('../images/header_back.png')no-repeat center / contain; text-indent:-9999px;  }
header p { font-size:18px; font-weight:bold; line-height:55px; color:#fff; }
header img { display:block; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); width:184px; }
/**/
footer { margin-top:40px; padding:25px 0; background:#4a4948; color:#fff; font-size:12px; }
footer address { margin-top:10px; opacity:0.5; line-height:1.8; }	
footer .link { margin:20px 0; }
footer .link a { font-size:14px; color:#fff !important; border-bottom:1px solid #fff; font-weight:300; line-height:1; }
footer .link p:first-child { margin-bottom:10px; }
footer .link2 { display:block; margin-bottom:10px; color:#fff !important; font-weight:bold; }
footer .copy { opacity:0.5; font-weight:300; }

/* common style */
.mt10 { margin-top:10px !important; }
.mt20 { margin-top:20px !important; }
.mon { font-family:'Montserrat', sans-serif; font-weight:500; }
.strong { font-weight:bold; }
.blue { color:#09c5c3; }
.red { color:#ec2329 !important; }
.line { border-bottom:1px solid #ec2329; }
.bar { margin:20px 0; height:1px; background:#d2d2d2; }
.center { text-align:center; }

.dot { position:relative; padding-left:15px; }
.dot:before { content:''; position:absolute; left:0; top:7px; display:block; width:4px; height:4px; border-radius:50%; background:#ec2329; }
.redbar { position:relative; padding-left:10px; }
.redbar:before { content:''; position:absolute; left:0; top:5px; display:block; width:4px; height:14px; border-radius:2px; background:#ec2329; }

.red-btn { display:block; width:150px; margin:40px auto 0; line-height:45px; background:#ec2329; color:#fff !important; font-size:15px; font-weight:bold; text-align:center; }
.border-btn { display:block; width:150px; line-height:45px; border:1px solid #4a4948; box-sizing:border-box; font-size:15px; font-weight:bold; text-align:center; }

/**/
.radio_style1 { height:40px; margin-bottom:5px; }
.radio_style1 label { float:left; width:49%; margin-right:2%; background:#f2f2f3; text-align:center; }
.radio_style1 label:last-child { margin-right:0; }
.radio_style1 label p { position:relative; display:inline-block; padding-left:22px; box-sizing:border-box; line-height:40px; }
.radio_style1 input[type="radio"] { height:100%; }
.radio_style1 input[type="radio"]  {display:none; }
.radio_style1 input[type="radio"]+label .icon { display:block; width:16px; height:16px; position:absolute; top:50%; left:0; margin-top:-8px;
	background:url('../images/radio_btn.png')no-repeat center / contain;}
.radio_style1 input[type="radio"]:checked+label .icon { background:url('../images/radio_btn_check.png')no-repeat center / contain; } 

.radio_style2 label { text-align:left; }
.radio_style2 label:last-child { margin-right:0; }
.radio_style2 label p { position:relative; display:inline-block; padding-left:22px; margin-right:20px; box-sizing:border-box; line-height:16px; }
.radio_style2 input[type="radio"] { height:100%; }
.radio_style2 input[type="radio"]  {display:none; }
.radio_style2 input[type="radio"]+label .icon { display:block; width:14px; height:14px; position:absolute; top:50%; left:0; margin-top:-7px;
	background:url('../images/radio2_btn.png')no-repeat center / contain;}
.radio_style2 input[type="radio"]:checked+label .icon { background:url('../images/radio2_btn_check.png')no-repeat center / contain; } 

.radio_style3 { overflow:hidden; border-top:1px solid #d2d2d2; }
.radio_style3 label { float:left; width:50%; text-align:center; line-height:50px; box-sizing:border-box; font-size:17px; background:#f2f2f3; }
.radio_style3 label span { position:relative; }
.radio_style3 label:last-child { border-left:1px solid #d2d2d2; }
.radio_style3 input[type="radio"]  {display:none; }
.radio_style3 input[type="radio"]:checked+label { background:#fff; }
.radio_style3 input[type="radio"]:checked+label span { color:#ec2329; }
.radio_style3 input[type="radio"]:checked+label span:before { content:''; position:absolute; left:-24px; top:0; width:19px; height:100%; 
	background:url('../images/icon_check.png')no-repeat left center / 19px; }

.checkbox { position:relative; height:40px; }
.checkbox label { display:block; padding:0 0 0 15px; text-align:left; box-sizing:border-box; }
.checkbox.style1 label { background:#f2f2f3; font-weight:bold; border:1px solid #d2d2d2; }
.checkbox.style2 label { border:1px solid #d2d2d2; }
.checkbox.style2 .arrow { position:absolute; top:0; right:0; display:block; width:40px; height:40px; 
	background:url('../images/iconSelectBg.png')no-repeat center / 10px; }
dl.on .checkbox.style2 .arrow { background-image:url('../images/iconClose.png'); }

.checkbox label p { position:relative; padding-left:22px; box-sizing:border-box; line-height:40px; }

.checkbox input[type="checkbox"] { height:100%; }
.checkbox input[type="checkbox"] {display:none; }
.checkbox input[type="checkbox"]+label .icon { display:block; width:16px; height:16px; position:absolute; top:50%; left:0; margin-top:-8px;
	background:url('../images/radio_btn.png')no-repeat center / contain;}
.checkbox.style1 input[type="checkbox"]+label .icon { background:url('../images/check_btn.png')no-repeat center / contain; }

.checkbox.style1 input[type="checkbox"]:checked+label .icon { background:url('../images/radio_btn_check.png')no-repeat center / contain; } 
.checkbox.style2 input[type="checkbox"]:checked+label .icon { background:url('../images/check_btn_check.png')no-repeat center / contain; } 