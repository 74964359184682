
/**/
.main .content { margin:0 15%; padding:70px 0; text-align:center; }
.main h1 { margin-bottom:60px; }
.main .red-btn { margin-top:70px; }
.view-link { margin-top:40px; }
.view-link a, .noti-link a { display:inline-block; padding-left:24px; padding-right:16px; }
.view-link a { margin-bottom:20px; background-image:url('../images/view-link.png'), url('../images/arrow_right.png');
	background-size:15px, 7px; background-position:left center, right center; background-repeat:no-repeat; }
.noti-link a { background-image:url('../images/noti-link.png'), url('../images/arrow_right.png');
	background-size:15px, 7px; background-position:left center, right center; background-repeat:no-repeat; }

/**/
.detail dl { margin-bottom:40px; }
.detail dt { margin-bottom:10px; font-size:17px; font-weight:500; }
.detail dd { line-height:1.8; }

/**/
.location { position:relative; margin-bottom:25px; font-weight:bold; }
.location p { font-size:20px; }
.location .step { position:absolute; right:0; top:50%; overflow:hidden; transform:translate(0,-50%); background:#e5e5e5; border-radius:20px; }
.location .step li { float:left; width:35px; line-height:20px; font-size:12px; border-radius:20px; text-align:center; font-weight:bold; }
.location .step li.on { background:#ec2329; color:#fff; }

/* form */
input[type=text], input[type=date], input[type=password], input[type=number] { height:40px; padding:10px; margin-bottom:5px; border:1px solid #d5d5d5; box-sizing:border-box; }
input::placeholder { color:rgba(75, 73, 72, 0.5); }
input:read-only { background:#f2f2f3; }
.color-grey { color:rgba(75, 73, 72, 0.5) !important; }
select option { color:#4b4948; }
.wFull { width:100%; }
.w4 { width:4%; }
.w32 { width:32%; }
.w48 { width:48%; }
.w49 { width:49%; }
.w68 { width:68%; }
.w70 { width:70%; }
.mb5 { margin-bottom:5px; }
.noti { padding-left:20px; background:url('../images/icon_noti.png')no-repeat left center / 15px; color:rgba(75, 73, 72, 0.6); }
.noti.red { margin-bottom:10px; padding-left:20px; background:url('../images/noti-link.png')no-repeat left center / 15px; font-size:14px; }

.form01 {  }
.form01 dl { width:100%; margin-bottom:20px; }
.form01 dt { overflow:hidden; margin-bottom:10px; font-size:17px; font-weight:500; }
.form01 dd { position:relative; width:100%; margin:0; line-height:40px; box-sizing:border-box; }
.form01 dd .input { margin-bottom:5px; }
.form01 dd .gray { float:right; display:block; line-height:40px; text-align:center; width:30%; color:#fff; background:#333; margin:0 0 0 2%;}
.form01 dd .gray2 { float:right; display:block; height:40px; line-height:40px; text-align:center; width:30%; color:#ec2329 ; background:#f2f2f3; margin:0; font-weight:bold; 
	border: 1px solid #d5d5d5; border-left:0; box-sizing:border-box; }
.form01 select { margin-bottom:5px; height:40px; padding:10px; border:1px solid #d5d5d5; }
.form01 span.sign { display:block; margin:0; width:11px; height:6px; position:absolute; top:18px; right:10px; background:url(../images/iconSelectBg.png)no-repeat right center / contain; }

.form01 .col3 { overflow:hidden; }
.form01 .col3 li { position:relative; float:left; width:32%; margin-right:2%; }
.form01 .col3 li:nth-of-type(3n) { margin-right:0%; }

.form01.type02 { margin-top:20px; }
.form01.type02 label { position:relative;; }
.form01.type02 label span { position:absolute; }
.form01.type02 label .name { left:10px; }
.form01.type02 label .unit { right:10px; opacity:0.5; }
.form01.type02 label input { text-align:right; padding:0 28px; box-sizing:border-box; }

.form01 .text { display:inline-block; float:right; text-align:right; line-height:27px; font-size:14px; }
input[name="email_domain"] { float:right; }
.email_select { position:relative; width:100%; }

.btn-wrap { overflow:hidden; width:100%; max-width:640px; margin:40px auto; }
.btn-wrap a { float:left; width:49%; margin-right:2%; }
.btn-wrap .red-btn { margin:0; }

/**/
.form_check table { margin-top:20px; }
.table01 { width:100%; }
.table01 tr { border-bottom:1px solid #d2d2d2; }
.table01 tr:first-child { border-top:1px solid #d2d2d2; }
.table01 th { padding:10px; background:#f2f2f3; text-align:left; border-right:1px solid #d2d2d2; font-weight:bold; word-break:keep-all; vertical-align:middle; }
.table01 td { padding:10px; padding-right:0; word-break:break-all; vertical-align:middle; }

/**/
.form02 dl { margin-top:5px; }
.form02 dd { display:none; border:1px solid #d2d2d2; border-top:0; font-size:13px; }
.form02 dd .dd_content { padding:20px; }
.form02 dd .table01 { margin:10px 0 20px; }
.close-dd { display:block; background:#f2f2f3; text-align:center; line-height:40px; font-size:14px; }
.close-dd span { padding-right:20px; background:url('../images/iconClose.png')no-repeat right center / 10px; }

.form02 dl.on > dd { display:block; }

.sign { overflow:hidden; margin-top:20px; }
.sign > p { font-size:17px; font-weight:bold; }
.sign div { margin:10px 0; border:1px solid #d2d2d2; min-height:150px; }
.sign-reset { float:right; display:inline-block; padding-left:20px; background:url(../images/sign-reset.png)no-repeat left center / 13px; }

/**/
.complete  { }
.sTitle { font-size:17px; margin-bottom:10px; }


.complete .content-wrap li { margin-bottom:10px;padding-left:20px; background:url(../images/icon_checkB.png)no-repeat left top 7px / auto 12px; }
.complete .grey-box { background-image:url('../images/grey-box-left.png'), url('../images/grey-box-right.png'); background-color:#f2f2f3; 
	background-size:auto 53px, auto 107px; background-position:left top, right bottom; background-repeat:no-repeat;
	text-align:center; padding:30px; margin-bottom:50px; }
.complete .grey-box p { padding-top:40px; background:url(../images/icon_notiB.png)no-repeat center top / 36px; }

.complete .red { position:relative; padding-left:17px; margin:10px 0; }
.complete .red:before { content:'※'; position:absolute; left:0; top:0; }
.complete .mon { font-size:15px; }
.complete .table01 { margin-top:10px; }
.complete .table01 td { word-break:keep-all; }

.video { width:100%; margin:30px auto; box-sizing:border-box; }
.video-container { position:relative; width:100%; height:auto; padding-top:50%; }
iframe { z-index:1; top:0; left:0; position:absolute; width:100%; height:100%; }


/**/
.question dl { position:relative; margin-top:20px; border:1px solid #d2d2d2; }
.question dt { padding:40px 15px; font-weight:500; font-size:17px; }
.question .num { position:absolute; top:0; left:0; width:50px; line-height:25px; background:#4a4948; color:#fff; text-align:center; border-radius:0 0 10px 0; }


.fl { float: left; }
.input-text-center { text-align: center; vertical-align: middle; line-height: 40px; }
.email-domain-select { display:block; margin:0; width:11px; height:6px; position:absolute; top:60px; right:10px; background:url(../images/iconSelectBg.png)no-repeat right center / contain; }
